.threeimgs{
  display:flex;
  gap:40px;
  margin-left: 178px;
  margin-top: 70px;
}
.study{
  margin-top: 35px;
  font-size: 20px;
  color:brown;
}
.fourthimg{
  margin-left: -800px;
  margin-top: 25px;
}
.content{
  margin-top: 30px;
}
.what{
  text-align: left;
  margin-left: 70px;
  font-size: 16px;
  margin-top: 25px;
}
.whatt{
  margin-top: 40px;
  font-weight: 600;
  text-decoration: underline;
}

  /* touriest visa css */
  .tourist{
    text-decoration: underline  ;
  }