  .info-wrap {
    h3 {
      color: #000;
      position: relative;
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 3px;
        background: #d62196;
        content: "";
      }
    }
    .dbox {
      width: 100%;
      /* color: rgba(255,255,255,.8); */
      margin-bottom: 25px;
      &:last-child() {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        span {
          font-weight: 400;
          color: #000;
        }
        a {
          color: rgba(0, 0, 0, 0.3);
        }
      }
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.05);
        span {
          font-size: 20px;
          color: #000;
        }
      }
      .text {
        width: calc(100% - 50px);
      }
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5 {
    line-height: 1.5;
    font-weight: 400;
    font-family: "Poppins", Arial, sans-serif;
    color: #000;
  }
  
  a {
    transition: 0.3s all ease;
    color: #ef8607;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none !important;
      outline: none !important;
      box-shadow: none;
    }
  }
  
  #contactForm {
    .form-control {
      font-size: 16px;
    }
  }
 .row{
    margin-top: 50px;
 }
 .first{
    color: white;
    margin-right: 198px;
    margin-top: 10px;
 }
 .number{
    color: white;
    margin-right: 165px;
    margin-top: 10px;
 }
 .email{
    color: white;
    margin-right: 270px;
    margin-top: 10px;
 }
 .optional{
    color: white;
    margin-right:105px ;
    margin-top: 10px;
 }
 .visa{
    color: white;
    margin-right: 205px;
    margin-top: 10px;
 }