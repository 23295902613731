@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* study visa css */
.threeimgs{
  display:flex;
  gap:40px;
  margin-left: 178px;
  margin-top: 70px;
}
.study{
  margin-top: 35px;
  font-size: 20px;
  color:brown;
}
.fourthimg{
  margin-left: -800px;
  margin-top: 25px;
}
.content{
  margin-top: 30px;
}
.what{
  text-align: left;
  margin-left: 70px;
  font-size: 16px;
  margin-top: 25px;
}
.whatt{
  margin-top: 40px;
  font-weight: 600;
  text-decoration:underline;
}
  /* touriest visa css */
  .tourist{
    text-decoration: underline;
  }

  /* imigration visa css */
  .imig{
    text-decoration: underline;
  }
  /* flag  css */
  .flag{
    width: 200px;
    height: 100px;
    padding-right:35px ;
  }

  .passport{
    height: 150px;
  }
  .book{
    height: 150px;
  }
